import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Api from '@launchpad/logic/api/Api';
import { currency } from '@launchpad/util/NumberHelper';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { AppButton } from '@launchpad/components/crud/AppButton';
import { LoadingOverlay } from '../../../../../../@launchpad/index';
import { date } from '../../../../../../@launchpad/util/DateHelper';

const AccountBasicInfo = props => {
  const [account, setAccount] = useState(props.account);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClose, setIsLoadingClose] = useState(false);

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  async function blockUnblockAccount(status) {
    try {
      setIsLoading(true);
      await Api.call(
        `admin/accounts/${account.id}/${
          status?.toLowerCase() === 'locked' ? 'unblock' : 'block'
        }`,
        'POST'
      );
      props.onRefresh();
    } catch (e) {
      console.log('ERR:', e);
    }
    setIsLoading(false);
  }

  async function closeAccount() {
    try {
      setIsLoadingClose(true);
      await Api.call(`admin/accounts/${account.id}/close`, 'POST');
      props.onRefresh();
    } catch (e) {
      console.log('ERR:', e);
    }
    setIsLoadingClose(false);
  }

  let status = 'ACTIVE';
  switch (account.status) {
    case 'pending_activation':
      status = 'PENDING ACTIVATION';
      break;
    case 'active':
    case 'ok':
      status = 'ACTIVE';
      break;
    case 'locked':
      status = 'LOCKED';
      break;
    case 'stolen':
      status = 'STOLEN';
      break;
    default:
      status = account.status ? account.status.toUpperCase() : 'UNKNOWN';
  }

  if (account.locked) {
    status = 'LOCKED';
  }

  return (
    <div>
      <div className="row">
        {isLoadingClose ||
        !account ||
        (account && Object.keys(account).length === 0) ? (
          <LoadingOverlay />
        ) : (
          <div className="col-xl-12">
            <div className="element-with-blur">
              <div className="dark-blur" />
              <div className="box-header marginless">
                <h2>
                  <i className="fa fa-address-card" /> Account Summary
                </h2>
              </div>

              <div className="box-body text-white padded">
                <div className="row">
                  <div className="col-sm-6">
                    <dl>
                      <dt>
                        <h3>Balance:</h3>
                      </dt>
                      <dd>
                        <h3>
                          {currency(account.availableBalance, account.currency)}
                        </h3>
                      </dd>
                      <dt>Name:</dt>
                      <dd>{account.name}</dd>
                      <dt>Account number:</dt>
                      <dd>{account.accountNumber}</dd>
                      <dt>IBAN:</dt>
                      <dd>{account.iban}</dd>
                      <dt>Sort code:</dt>
                      <dd>{account.sortCode}</dd>
                      <dt>Swift Bic:</dt>
                      <dd>{account.swiftBic}</dd>
                      <dt>Currency:</dt>
                      <dd>{account.currency}</dd>
                      <dt>Balance created:</dt>
                      <dd>{date(account.created, 'D / MMMM / Y')}</dd>
                      <dt>Status:</dt>
                      <dd>{status}</dd>
                    </dl>
                  </div>
                  {/* <div className="col-sm-6">
                    <dl>
                      {account.status?.toLowerCase() !== 'closed' && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <DeleteButton
                            block
                            btnText="Close"
                            onConfirm={() => closeAccount()}
                            text="Are you sure you want to close account?"
                            style={{
                              width: 200,
                              minHeight: 40,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                            isLoading={isLoadingClose}
                          />
                          <AppButton
                            type="button"
                            onClick={() => blockUnblockAccount(account.status)}
                            customstyle="custom-danger-btn"
                            style={{
                              border: `none !important`,
                              width: 200,
                              minHeight: 40,
                              textTransform: 'none',
                              marginLeft: 10
                            }}
                            isLoading={isLoading}
                          >
                            {account.status?.toLowerCase() === 'locked'
                              ? 'Unlock'
                              : 'Lock'}
                          </AppButton>
                        </div>
                      )}
                    </dl>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '20px' }}>
              <div className="col-xl-12">
                {/* <LastFiveTransactions accountId={this.props.account.id} /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(AccountBasicInfo);
