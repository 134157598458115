/* eslint-disable react/require-default-props */
import { date } from '@launchpad/util/DateHelper';
import React, { useState } from 'react';

interface Props {
  title: string;
  arrayOfItems: Array<
    | { label: string; value: string; isDate?: boolean }
    | { table: Array<any>; label: string; columns?: Array<string> }
  >;
  repeatCheck?: () => void;
}

const KycCollapsibleItem: React.FC<Props> = ({
  title,
  arrayOfItems,
  repeatCheck
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const camelCaseToHumanReadable = (str: string) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function(s) {
      return s.toUpperCase();
    });
  };

  // check if string is date
  const convertToDateIfStringIsDate = (
    d: string | number,
    isDate?: boolean
  ) => {
    if (typeof d === 'number') {
      return d;
    }
    const onlyNumbers = /^\d+$/.test(d);
    // check if d contains at least 3 dots (mm.dd.yyyy ) - temp solution to avoid converting string into date
    // console.log(new Date('7.22').toString()); // outputs "Mon Jan 01 7 06:00:00 GMT+0600 (Central Standard Time)"
    const containsDotsOrDashes = d && (d.match(/\.|-/g) || []).length >= 2;

    // check if d contains at least 2 dots or 2 -
    if (
      !onlyNumbers &&
      containsDotsOrDashes &&
      new Date(d).toString() !== 'Invalid Date' &&
      isDate !== false
    ) {
      return date(d, 'DD/MM/YYYY');
    }
    return d;
  };

  // beautify json stringify output
  const beautifyJson = (json: string) => {
    const replacer = (key: string, value: any) => {
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    };
    return JSON.stringify(JSON.parse(json), replacer, 2)
      .replace(/"([^"]+)":/g, '$1:')
      .replace(/"/g, '')
      .replace(/[{}[\]]/g, '');
  };

  const renderTablePreview = (table, label, columns) => {
    if (!table || table.length === 0) return null;

    const keys = columns || Object.keys(table[0]);
    return (
      <>
        {label && <span style={{ fontWeight: 'bold' }}>{label}:</span>}
        <table className="table">
          <tbody>
            <tr className="tr-dark-bg">
              {keys.map(key => {
                if (key === 'customDateOfBirth') {
                  return <td>{camelCaseToHumanReadable('dateOfBirth')}</td>;
                }
                if (!columns || columns.includes(key)) {
                  return <td>{camelCaseToHumanReadable(key)}</td>;
                }
                return null;
              })}
            </tr>
            {table.map(item => {
              // spread if item[key] is object or array
              return (
                <tr>
                  {keys.map(key => {
                    if (!columns || columns.includes(key)) {
                      if (key === 'customDateOfBirth') {
                        return (
                          <td>
                            {convertToDateIfStringIsDate(
                              `${item.birthDay || '-'}/${item.birthMonth ||
                                '-'}/${item.birthYear || '-'}`
                            )}
                          </td>
                        );
                      }
                      return (
                        <td>
                          {typeof item[key] === 'object' ||
                          Array.isArray(item[key])
                            ? beautifyJson(JSON.stringify(item[key]))
                            : convertToDateIfStringIsDate(item[key])}
                        </td>
                      );
                    }
                    return null;
                  })}
                </tr>
              );
              // // render each item value, item is object
              // return keys.map(key => <td>{item[key]}</td>);
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 20
        }}
        onClick={toggleCollapse}
      >
        <h4>{title}</h4>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {repeatCheck && (
            <button
              type="button"
              onClick={event => {
                event.stopPropagation();
                repeatCheck();
              }}
              style={{ marginRight: 25 }}
              className="button-info btn-block"
            >
              Repeat check
            </button>
          )}
          <i className={`fa fa-chevron-${isCollapsed ? 'up' : 'down'}`} />
        </div>
      </div>
      <hr />
      {isCollapsed && (
        <dl>
          {arrayOfItems.map(item => {
            if ('table' in item) {
              // handle table case
              return renderTablePreview(item.table, item.label, item.columns);
            }
            if (typeof item.value === 'object') {
              return (
                <>
                  {Object.keys(item.value).map(key => (
                    <>
                      <dt>
                        {item.label} {camelCaseToHumanReadable(key)}:
                      </dt>
                      <dd>
                        <span key={key}>
                          {convertToDateIfStringIsDate(item.value[key])}
                        </span>
                      </dd>
                    </>
                  ))}
                </>
              );
            }
            return (
              <>
                <dt>{item.label}:</dt>
                <dd>
                  {convertToDateIfStringIsDate(item.value, item.isDate) || '-'}
                </dd>
              </>
            );
          })}
        </dl>
      )}
    </div>
  );
};

export default KycCollapsibleItem;
